import config from "./config";

export default {
  styleOverrides: {
    root: {
      paddingTop: config.spacing.sm,
      paddingBottom: config.spacing.sm,

      "MuiMenuItem-root.Mui-selected &, MuiMenuItem-root.Mui-selected:hover &, &.Mui-selected.Mui-focusVisible, MuiMenuItem-root:hover &":
        {
          backgroundColor: "unset !important",
          color: `${config.colors.orange.brandy} !important`,
        },
    },
  },
};
