import { useMutation } from "@apollo/client";
import { differenceInMinutes, differenceInSeconds } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { anyPass, find, isEmpty, isNil, not, pathEq, propEq, subtract } from "ramda";
import { useCallback } from "react";

import {
  CREATE_EVENT_ATTACHMENT,
  GENERATE_ATTACHMENT_UPLOAD_URL,
} from "@hey-lady/gql/mutations/event";

import { upload } from "@hey-lady/shared/helpers/file";
import { useOnOffSwitch } from "@hey-lady/shared/hooks/general";

import { UNLIMITED, WebinarEvents } from "../helpers/const";
import { isInPast } from "../helpers/date-time.ts";
import { getImageDimensions, resizeImage } from "../helpers/image";
import { getTimezone } from "../helpers/timezone";
import {
  ConversationKind,
  type EventAttachmentModel,
  EventKind,
  type EventModel,
  EventPermissions,
  InPersonEventConfirmationStatus,
  ParticipationStatus,
  type UserModel,
  useGetParticipationStatusQuery,
  useUploadEventDescriptionImageMutation,
} from "../types/graphql";
import { useErrorHandler } from "./errorHandler";

export const useUploadEventAttachment = ({
  eventId,
  onAfterUpload,
}: {
  eventId?: string;
  onAfterUpload: (attachment: EventAttachmentModel) => void;
}) => {
  const [loading, onStart, onEnd] = useOnOffSwitch();

  const [createEventAttachment] = useMutation(CREATE_EVENT_ATTACHMENT);
  const [generateAttachmentUploadUrl] = useMutation(GENERATE_ATTACHMENT_UPLOAD_URL);

  const onDrop = async ([file]: File[]): Promise<void> => {
    const variables = {
      eventID: eventId,
      filename: file.name,
    };

    try {
      onStart();
      const { data } = await generateAttachmentUploadUrl({ variables });
      await upload(data?.generateAttachmentUploadUrl?.uploadURL, file);
      const url = data?.generateAttachmentUploadUrl?.contentURL;
      const meta = { name: file.name, size: file.size, extension: file.type };
      const attachment = await createEventAttachment({
        variables: { eventID: eventId, input: { url, meta } },
      });
      onAfterUpload(attachment?.data?.createAttachment);
    } catch (error) {
      console.error(error);
    } finally {
      onEnd();
    }
  };

  return {
    loading,
    onDrop,
  } as const;
};

export const useGetEventInfo = (event: EventModel) => {
  const currentTimezone = getTimezone();
  const eventStartDate = toZonedTime(event.startDate, currentTimezone);
  const eventEndDate = toZonedTime(event.endDate, currentTimezone);
  const now = new Date();
  const eventIsToday = differenceInMinutes(eventStartDate, now) < 60;
  const eventIsStarting = differenceInSeconds(eventStartDate, now) <= 0;
  const eventHasClosedDoor = not(isNil(event.closedDoorAt));
  const isPastEvent = isInPast(eventEndDate);
  const openWithDaily = event.meta.privateRoomName && not(event.meta.startURL);
  const isOneToOne = event.kind === EventKind.OneToOne;
  const isDeepDive = event.conversationKind === ConversationKind.DeepDive;
  const isInPerson = event.kind === EventKind.InPerson;
  const isInPersonConfirmed =
    isInPerson && event.confirmationStatus !== InPersonEventConfirmationStatus.Initial;
  const freeSeats = calculateFreeSeats(event);
  const unlimitedEvent = event.maxParticipants === UNLIMITED;
  const eventCanBeAttended =
    event.isPublic &&
    not(isPastEvent) &&
    not(isOneToOne) &&
    not(eventHasClosedDoor) &&
    (unlimitedEvent ||
      (event.noParticipants ?? 0) < (event.maxParticipants ?? Number.POSITIVE_INFINITY));
  const isMemberHostedEvent = ![event.host, ...event.cohosts].every(
    (host) =>
      host &&
      [
        EventPermissions.HeyLadyAdmin,
        EventPermissions.HeyLadyTeam,
        EventPermissions.BigSister,
      ].includes(host?.kind ?? EventPermissions.HeyLadyTeam),
  );
  const isConversationStarter = event.kind === EventKind.ConversationStarter;

  return {
    eventStartDate,
    eventEndDate,
    isPastEvent,
    eventIsToday,
    eventIsStarting,
    eventHasClosedDoor,
    openWithDaily,
    isOneToOne,
    isDeepDive,
    isInPerson,
    isInPersonConfirmed,
    freeSeats,
    eventCanBeAttended,
    isMemberHostedEvent,
    isConversationStarter,
  };
};
const calculateFreeSeats = (event: EventModel) => {
  if (
    (event.maxParticipants && event.noParticipants) ||
    (event.maxParticipants && event.noParticipants === 0)
  ) {
    return subtract(event.maxParticipants, event.noParticipants);
  }
};

export const useGetEventUserInfo = ({ event, me }: { event: EventModel; me: UserModel }) => {
  const participationStatusResult = useGetParticipationStatusQuery({
    fetchPolicy: "network-only",
    variables: {
      input: {
        eventId: event.id,
        userId: me.id,
      },
    },
  });

  const participationStatus = participationStatusResult.data?.getParticipationStatus.status;

  const unlimitedEvent = event.maxParticipants === UNLIMITED;

  const userCanAttend = (() => {
    const eventHasPlaces =
      unlimitedEvent ||
      (event.noParticipants ?? 0) < (event.maxParticipants ?? Number.POSITIVE_INFINITY);

    // condition for both public and private events
    if (participationStatus === ParticipationStatus.Invited) {
      return true;
    }

    if (event.isPublic) {
      return eventHasPlaces;
    }

    // condition for private events when user first declined the invite
    return participationStatus === ParticipationStatus.Declined && eventHasPlaces;
  })();

  return {
    participationStatus,
    unlimitedEvent,
    userCanAttend,
  };
};

export const userHasPermission = (event: EventModel, user: UserModel) => {
  if (isStaff(user)) {
    return true;
  }
  if (isHostOrCohost(event, user)) {
    return true;
  }
  if (isInvited(event, user)) {
    return true;
  }
  if (not(user.isVerified)) {
    return event.isForUnverified;
  }
  const allowedForAllKinds = isEmpty(event.permissions) || isNil(event.permissions);
  const allowedForMyKind = user.kind && event.permissions?.includes(user.kind);
  return allowedForMyKind || allowedForAllKinds;
};

export const isStaff = (me: UserModel) => {
  if (isNil(me.kind)) {
    console.error("User kind is undefined.");
    return false;
  }
  return [
    EventPermissions.BigSister,
    EventPermissions.HeyLadyAdmin,
    EventPermissions.HeyLadyTeam,
  ].includes(me.kind);
};

export const isAdminOrTeam = (me: UserModel) => {
  if (isNil(me.kind)) {
    console.error("User kind is undefined.");
    return false;
  }
  return [EventPermissions.HeyLadyAdmin, EventPermissions.HeyLadyTeam].includes(me.kind);
};

export const isHostOrCohost = (event: EventModel, me: UserModel) => {
  if (me.id === event.host?.id) {
    return true;
  }
  if (isNil(event.cohosts) || isEmpty(event.cohosts)) {
    return false;
  }
  return find(propEq(me.id, "id"))(event.cohosts) !== undefined;
};

const isInvited = (event: EventModel, me: UserModel) => {
  if (isNil(event.invites) || isEmpty(event.invites)) {
    return false;
  }
  return find(pathEq(me.id, ["user", "id"]))(event.invites) !== undefined;
};

const isNilOrEmpty = anyPass([isNil, isEmpty]);

export const getEventDraftRoute = (event: EventModel) => {
  if (isNilOrEmpty(event.title) || isNilOrEmpty(event.description)) {
    return `/edit/events/${event?.id}/details`;
  }
  if (isNilOrEmpty(event.startDate)) {
    return `/edit/events/${event?.id}/time`;
  }
  if (isNilOrEmpty(event.host) || !isTypeOfMembersSet(event)) {
    return `/edit/events/${event?.id}/participants`;
  }
  if (isNilOrEmpty(event.coverUrl)) {
    return `/edit/events/${event?.id}/cover`;
  }
  return `/edit/events/${event?.id}/confirmation`;
};

const isTypeOfMembersSet = (event: EventModel) => {
  const canSelectUserPlatformAge = event.organizer && isStaff(event.organizer);
  const validPermissions =
    not(isNilOrEmpty(event.permissions)) ||
    event.isForUnverified ||
    event?.kind === EventKind.InPerson;
  if (!canSelectUserPlatformAge || (canSelectUserPlatformAge && validPermissions)) {
    return true;
  }
  return false;
};

export const useUserCanEditEvent = (event: EventModel, me: UserModel) => {
  if (isNil(event.organizer)) {
    console.error("Event organizer must be defined");
  }
  const amIOrganizer = event.organizer?.id === me.id;
  const amIHost = isHostOrCohost(event, me);
  const amIAdminOrTeam = isAdminOrTeam(me);
  const isOrganizerAdminOrTeam = event?.organizer ? isAdminOrTeam(event.organizer) : false;

  const canIEditStaffEvent = amIHost && amIAdminOrTeam && isOrganizerAdminOrTeam;
  const canIEditMemberEvent = amIHost && !amIAdminOrTeam && !isOrganizerAdminOrTeam;

  return amIOrganizer || canIEditStaffEvent || canIEditMemberEvent;
};

export const useUserCanDeleteEvent = (event: EventModel, me: UserModel) => {
  if (isNil(event.organizer)) {
    console.error("Event organizer must be defined");
  }
  return event.organizer?.id === me.id;
};

export const useGetEventMembersToConnect = (event: EventModel, me: UserModel) => {
  if (!event) {
    return [];
  }
  const eventMembers = [
    ...event.participants,
    ...event.cohosts,
    ...(event?.host ? [event?.host] : []),
  ];
  return eventMembers.filter((member) => !isAdminOrTeam(member) && member.id !== me.id);
};

export const useShowConnectParticipants = (event: EventModel, me: UserModel) => {
  const membersToConnect = useGetEventMembersToConnect(event, me);
  if (!event?.kind) {
    return false;
  }
  return (
    !isEmpty(membersToConnect) &&
    event.kind !== EventKind.OneToOne &&
    !WebinarEvents.includes(event.kind)
  );
};

export const useUploadEventImage = (eventId: string) => {
  const { handleError } = useErrorHandler();

  const [uploadEventDescriptionImage, { loading }] = useUploadEventDescriptionImageMutation();

  const uploadEventImage = useCallback(
    async (file: File): Promise<string> => {
      const dimensions = await getImageDimensions(file);
      const image = resizeImage(dimensions);

      const variables = {
        id: eventId,
        input: {
          file,
          height: image.height,
          width: image.width,
        },
      };
      const response = await handleError(uploadEventDescriptionImage)({ variables });
      return response?.data?.uploadEventDescriptionImage?.contentURL ?? "";
    },
    [eventId, handleError, uploadEventDescriptionImage],
  );

  return {
    loading,
    uploadEventImage,
  } as const;
};
