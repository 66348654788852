import { gql } from "@apollo/client";

export const ME = gql`
  query me {
    me {
      id
      email
      subscriptionTier
      avatarUrl
      isActive
      isVerified
      firstName
      isComplete
      completedPercentage
      completedHomeTour
      badge
      createdAt
      lastName
      name
      hasActivePlan
      kind
      timezone
      hasGoogleRefreshToken
      badges {
        id
        type
        achievedDate
      }
      following {
        id
      }
      followers {
        id
      }
      city
      country
      cityId
      countryId
      lat
      long
      cambridgeEnglishLevel
      speakingLevelScore
      speakingLevel
      birthday
      showBirthday
      showBirthdayYear
      needSpeakingPartner
      englishSkills
      interests
      occupation {
        id
        name
      }
      story
      availabilityWeekdayFrom
      availabilityWeekdayUntil
      availabilityWeekendsFrom
      availabilityWeekendsUntil
      availability {
        from
        until
      }
      planSlug
      deletedAt
      promotionalCredits
      impersonated
      seenCompletedAt
      stepsCompleted
      lastOnlineAt
      availableAssessmentCharges
    }
  }
`;

export const MY_PROFILE = gql`
  query myProfile {
    me {
      id
      email
      avatarUrl
      subscriptionTier
      needSpeakingPartner
      cambridgeEnglishLevel
      speakingLevelScore
      speakingLevel
      completedPercentage
      isVerified
      firstName
      nationality
      otherLanguage
      isComplete
      badge
      createdAt
      lastName
      city
      country
      cityId
      countryId
      lat
      long
      story
      birthday
      englishSkills
      interests
      occupation {
        id
        name
      }
      badges {
        id
        type
        achievedDate
      }
      engagementMetrics {
        attendanceMetrics {
          totalEvents
          totalOneToOnes
          totalGroup
          totalWebinars
          totalInPerson
          totalOther
          totalDuration
          totalHostedEvents
          totalHostedOneToOnes
          totalHostedGroup
          totalHostedWebinars
          totalHostedInPerson
          totalHostedOther
          totalHostedDuration  
          totalParticipatedEvents
          totalParticipatedScheduledEvents
          totalParticipatedOneToOnes
          totalParticipatedGroup
          totalParticipatedWebinars
          totalParticipatedInPerson
          totalParticipatedOther
          totalParticipatedDuration  
          totalVideoCalls  
        }
        publishedArticles
        recentArticles {
           id
           title
           coverUrl
           noLikes
           likes {
              id
           } 
        }  
        totalHostedParticipants
      }
      showBirthday
      showBirthdayYear
      nativeLanguage
      name
      wantsNewsletter
      completedPercentage
      completedHomeTour
      speakingPartners
      hasActivePlan
      kind
      timezone
      hasGoogleRefreshToken
      following {
        id
      }
      followers {
        id
      }
      availabilityWeekdayFrom
      availabilityWeekdayUntil
      availabilityWeekendsFrom
      availabilityWeekendsUntil
      availability {
        from
        until
      }
      photos {
        id
        url
      }
      planSlug
      deletedAt
      promotionalCredits
      impersonated
      seenCompletedAt
      stepsCompleted
    }
  }
`;

export const USER_SETTINGS = gql`
  query getUserSettings {
    getUserSettings {
      id
      receiveEventUpdatesAndInvitationsNotifications
      receiveEventUpdatesAndInvitationsEmails
      receiveEventRemindersNotifications
      receiveEventRemindersEmails
      receiveEventRsvpNotifications
      receiveEventRsvpEmails
      receiveFollowsNotifications
      receiveFollowsEmails
      receiveMentionsAndCommentsNotifications
      receiveMentionsAndCommentsEmails
      receiveChatMentionsNotifications
      receiveChatMentionsEmails
      receiveLiveTableStartedNotifications
      receivePlatformStatusEmails
      receiveSubscriptionChangesEmails
      emailNotifications
      updatedAt
      createdAt
    }
  }
`;

export const USER = gql`
  query user($id: String!) {
    getUser(id: $id) {
      id
      email
      avatarUrl
      needSpeakingPartner
      cambridgeEnglishLevel
      speakingLevelScore
      speakingLevel
      firstName
      otherLanguage
      isComplete
      badge
      createdAt
      lastOnlineAt
      lastName
      city
      name
      country
      story
      birthday
      englishSkills
      interests
      occupation {
        id
        name
      }
      showBirthday
      nativeLanguage
      completedPercentage
      followers {
        follower {
          id
          name
        }
      }
      availability {
        from
        until
      }
      photos {
        id
        url
      }
    }
  }
`;

export const SIMPLE_USER = gql`
  query simpleUser($id: String!) {
    getUser(id: $id) {
      id
      firstName
    }
  }
`;

export const PLAN_DETAILS_AND_PAYMENT_METHOD = gql`
  query planDetailsAndPaymentMethod {
    planDetails {
      meta
      interval
      intervalCount
      name
      amount
      userAmount
      start
      end
      status
      resumesAt
      cancelledAt
    }
    paymentMethod {
      last4Digits
      expYear
      expMonth
      type
      email
    }
    invoices {
      id
      created
      description
      amount
      currency
      downloadUrl
      status
    }
  }
`;

export const CAN_PAUSE = gql`
  query canPause {
    canPause {
      canPause
      pauseCredits
    }
  }
`;

export const GET_OCCUPATIONS = gql`
  query getOccupations {
    getOccupations {
      id
      name
    }
  }
`;

export const USER_HAS_NO_ACCEPTED_INVITES = gql`
  query userHasNoAcceptedInvites {
    userHasNoAcceptedInvites
  }
`;

export const ATTENDED_EVENTS = gql`
  query attendedEvents($input: StatisticsInput!) {
    attendedEvents(input: $input) {
      oneToOnes
      groupEvents
      webinars
      inPersonEvents
      other
    }
  }
`;

export const ATTENDANCE_RECORD = gql`
  query attendanceRecord($input: StatisticsInput!) {
    attendanceRecord(input: $input) {
      attendanceRatio
      totalRsvps
      attended
    }
  }
`;

export const PARTICIPATION_TIME = gql`
  query participationTime($input: StatisticsInput!) {
    participationTime(input: $input)
  }
`;

export const GLOBAL_NETWORK = gql`
  query globalNetwork($input: StatisticsInput!) {
    globalNetwork(input: $input) {
      lat
      long
    }
  }
`;

export const FRIENDLY_FACES = gql`
  query friendlyFaces($input: StatisticsInput!) {
    friendlyFaces(input: $input) {
      userId
      firstName
      lastName
      avatarUrl
    }
  }
`;

export const ARTICLES_WRITTEN = gql`
  query articlesWritten($input: StatisticsInput!) {
    articlesWritten(input: $input)
  }
`;

export const DAILY_EVENTS = gql`
  query dailyEvents($input: StatisticsInput!) {
    dailyEvents(input: $input) {
      day
      count
    }
  }
`;

export const HOSTED_EVENTS_STATISTICS = gql`
  query hostedEventsStatistics($input: StatisticsInput!, $prevInput: StatisticsInput!) {
    hostedEvents(input: $input)
    previousHostedEvents: hostedEvents(input: $prevInput)
    ladiesAttended(input: $input)
    userHostedDistinctSparks(input: $input)
  }
`;

export const MONTHLY_RECAP_STEPS = gql`
  query monthlyRecapSteps($input: StatisticsInput!) {
    monthlyRecapSteps(input: $input)
  }
`;

export const SEEN_MONTHLY_REPORT = gql`
  query seenMonthlyReport {
    seenMonthlyReport
  }
`;

export const GET_GROUPED_OCCUPATIONS = gql`
  query getGroupedOccupations {
    getGroupedOccupations {
      id
      name
      industry {
        id
        name
      }
    }
  }
`;

export const GET_SPARKS = gql`
  query getSparks($input: StatisticsInput!) {
    userDistinctSparks(input: $input)
    userAttendedDistinctSparks(input: $input)
    communityDistinctSparks(input: $input)
  }
`;

export const GET_FRIEND = gql`
    query getFriend($id: String!) {
        getFriend(id: $id) {
            firstName
            avatarUrl
        }
    }
`;

export const GET_USERS_WITH_SAME_SPEAKING_LEVEL = gql`
  query getUsersWithSameSpeakingLevel($speakingLevel: SpeakingLevelCategories!) {
    getUsersWithSameSpeakingLevel(speakingLevel: $speakingLevel) {
      total
      randomUsersAvatars
    }
  }
`;
